import { Component, effect, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Button } from 'primeng/button';
import { DatePicker } from 'primeng/datepicker';
import { InputText } from 'primeng/inputtext';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Ripple } from 'primeng/ripple';
import { Select } from 'primeng/select';
import { Textarea } from 'primeng/textarea';
import { StoreService } from '../../core/store/store.service';
import { CompanyInterface } from '../../core/interfaces/accounts/company-interface';
import { SendService } from '../../core/services/send/send.service';
import { DriverService } from '../../core/services/driver-service/driver.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BlockUI } from 'primeng/blockui';
import { Message } from 'primeng/message';

@Component({
  selector: 'magenty-appointment',
  imports: [
    CommonModule,
    Button,
    DatePicker,
    InputText,
    ReactiveFormsModule,
    Ripple,
    Select,
    Textarea,
    BlockUI,
    Message,
  ],
  providers: [DatePipe],
  templateUrl: './appointment.component.html',
  styleUrl: './appointment.component.scss',
})
export class AppointmentComponent {
  storeService = inject(StoreService);
  datePipe = inject(DatePipe);
  sendService = inject(SendService);
  driverService = inject(DriverService);
  appointmentForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    surName: new FormControl('', [Validators.required]),
    appointmentDate: new FormControl(new Date(), [Validators.required]),
    appointmentHour: new FormControl('', [Validators.required]),
    note: new FormControl('', []),
  });
  minDate: Date = new Date();
  maxDate: Date = new Date(new Date().setDate(new Date().getDate() + 13));

  blocked = false;

  timeSlots = [
    { label: '09:00', value: '09:00' },
    { label: '09:30', value: '09:30' },
    { label: '10:00', value: '10:00' },
    { label: '10:30', value: '10:30' },
    { label: '11:00', value: '11:00' },
    { label: '11:30', value: '11:30' },
    { label: '13:00', value: '13:00' },
    { label: '13:30', value: '13:30' },
    { label: '14:00', value: '14:00' },
    { label: '14:30', value: '14:30' },
    { label: '15:00', value: '15:00' },
    { label: '15:30', value: '15:30' },
    { label: '16:00', value: '16:00' },
    { label: '16:30', value: '16:30' },
  ];
  company: CompanyInterface | undefined;

  constructor(
    public ref: DynamicDialogRef,
    private dialogService: DialogService
  ) {
    this.appointmentForm.reset();
    effect(() => {
      if (this.storeService.store$()) {
        const companyBase = this.storeService.store$()?.company?.base;
        this.company = companyBase;
        const remainingTime = +(companyBase?.RemainingTime ?? '0');

        if (remainingTime > 0) {
          this.maxDate = new Date(
            new Date().setDate(new Date().getDate() + remainingTime)
          );
        }

        this.appointmentForm.patchValue({
          name: this.storeService.store$()?.company?.base?.Name ?? '',
          surName: this.storeService.store$()?.company?.base?.Surname ?? '',
          appointmentHour: '',
          appointmentDate: new Date(),
        });
      }
    });
  }

  startTour() {
    const tourSteps = [
      {
        element: '#infoset-btn-frame',
        popover: {
          title: 'Canlı Destek ile Yanınızdayız!',
          description: `Herhangi bir sorunuz veya ihtiyacınız olduğunda, buradan canlı destek ekibimize anında ulaşabilirsiniz. Uzman ekibimiz, size en hızlı ve en doğru çözümü sunmak için hazır!`,
        },
      },
    ];

    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const iframe = document.querySelector(
            '#infoset-btn-frame'
          ) as HTMLIFrameElement;

          if (iframe) {
            this.driverService.showButtons(['next', 'close']);
            this.driverService.startDriver(tourSteps);
            observer.disconnect();
          }
        }
      }
    });

    const targetNode = document.body;

    const config = { childList: true, subtree: true };

    observer.observe(targetNode, config);
  }

  onSubmit(): void {
    if (this.appointmentForm.valid) {
      let formValue: any = { ...this.appointmentForm.value };

      // Loglama için tarihi string formatına dönüştür
      const formattedDate = this.datePipe.transform(
        formValue.appointmentDate,
        'dd.MM.yyyy'
      );

      formValue = {
        ...formValue,
        appointmentDate: formattedDate,
      };

      const html = `<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <title>Yeni Randevu Talebi</title>
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap" rel="stylesheet">
</head>
<body style="font-family: 'Inter', sans-serif; line-height: 1.6; color: #334155; background-color: #f1f5f9; margin: 0; padding: 40px 20px;">
    <div style="max-width: 600px; margin: 0 auto; background-color: #ffffff; border-radius: 16px; box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1); padding: 32px;">
        <div style="text-align: center; margin-bottom: 32px;">
            <div style="background-color: #f0fdf4; width: 64px; height: 64px; border-radius: 50%; display: inline-flex; align-items: center; justify-content: center; margin-bottom: 16px;">
                <span style="font-size: 32px;">🗓️</span>
            </div>
            <h2 style="color: #0f172a; margin: 0; font-weight: 600;">Yeni Randevu Talebi</h2>
        </div>

        <div style="background-color: #f8fafc; border-radius: 12px; padding: 24px; margin-bottom: 32px;">
            <div style="display: grid; gap: 16px;">
                <div style="display: grid; grid-template-columns: 140px 1fr; gap: 12px;">
                    <span style="color: #64748b; font-weight: 500;">Ad Soyad:</span>
                    <span style="color: #334155;">${formValue.name} ${formValue.surName}</span>
                </div>
                <div style="display: grid; grid-template-columns: 140px 1fr; gap: 12px;">
                    <span style="color: #64748b; font-weight: 500;">Şirket:</span>
                    <span style="color: #334155;">${this.company?.Title}</span>
                </div>
                <div style="display: grid; grid-template-columns: 140px 1fr; gap: 12px;">
                    <span style="color: #64748b; font-weight: 500;">Şirket Mail:</span>
                    <span style="color: #334155;">${this.company?.Email}</span>
                </div>  <div style="display: grid; grid-template-columns: 140px 1fr; gap: 12px;">
                    <span style="color: #64748b; font-weight: 500;">Şirket Telefon:</span>
                    <span style="color: #334155;">${this.company?.Phone}</span>
                </div>
                <div style="display: grid; grid-template-columns: 140px 1fr; gap: 12px;">
                    <span style="color: #64748b; font-weight: 500;">Kalan Gün:</span>
                    <span style="color: #334155;">${this.company?.RemainingTime}</span>
                </div>
                <div style="display: grid; grid-template-columns: 140px 1fr; gap: 12px;">
                    <span style="color: #64748b; font-weight: 500;">Randevu Tarihi:</span>
                    <span style="color: #334155;">${formValue.appointmentDate}</span>
                </div>
                <div style="display: grid; grid-template-columns: 140px 1fr; gap: 12px;">
                    <span style="color: #64748b; font-weight: 500;">Randevu Saati:</span>
                    <span style="color: #334155;">${formValue.appointmentHour}</span>
                </div>
                <div style="display: grid; grid-template-columns: 140px 1fr; gap: 12px;">
                    <span style="color: #64748b; font-weight: 500;">Not:</span>
                    <span style="color: #334155;">${formValue.note}</span>
                </div>
            </div>
        </div>


    </div>
</body>
</html>`;

      this.sendService
        .supportMail('Ücretsiz Eğitim Randevusu', html)
        .subscribe((f) => {
          if (f && f.Result && f.Result.Status) {
            this.blocked = true;
            setTimeout(() => {
              this.blocked = false;
              this.appointmentForm.reset();
              this.startTour();
              this.ref.close();
            }, 4000);
          }
        });
    } else {
      Object.keys(this.appointmentForm.controls).forEach((key) => {
        const control = this.appointmentForm.get(key);
        if (control) {
          control.markAsTouched();
        }
      });
    }
  }
}
