@if(blocked) {
  @let date=appointmentForm.get('appointmentDate')?.value ?? '';
  @let hour=appointmentForm.get('appointmentHour')?.value ?? '';
  <p-blockUI [blocked]="blocked">

    <div class="flex flex-col items-center justify-center gap-4 w-full bg-black/50">
      <img src="assets/gif/verified.gif" width="150" height="150" alt="template-download" />

      <div class="text-center mt-4">
        <p class="text-xl text-white">Talebiniz Başarıyla Alındı!</p>
        <span class="block max-w-[35rem] text-lg">
          <span class="font-bold text-primary">{{( date | date:'dd.MM.yyyy') }}</span> tarihinde, saat <span class="font-bold text-primary">{{ hour }}</span>
          için randevunuz oluşturuldu. Destek ekibimiz en kısa sürede sizinle iletişime geçecek.</span>
      </div>
    </div>


  </p-blockUI>
}

<div class="my-2">
  <p-message icon="pi pi-info-circle" severity="info">Panelimizi daha yakından tanımak ve tüm özelliklerini keşfetmek için 15 dakikalık ücretsiz eğitim randevunuzu hemen oluşturun! Uzman ekibimiz, size özel bir sunumla panelimizi en etkili şekilde kullanmanıza yardımcı olacak.</p-message>
</div>


<form [formGroup]="appointmentForm">
    <div class="border-b border-gray-900/10 pb-6">

      <div class="grid grid-cols-1 gap-x-4 gap-y-4 lg:grid-cols-12">
        <!-- İsim -->
        <div class="col-span-12 lg:col-span-6">
          <label for="name" class="m-form-label">İsim</label>
          <input
            pInputText
            id="name"

            formControlName="name"
            placeholder="İsim"
            class="w-full">
          @if (appointmentForm.get('name')?.invalid && (appointmentForm.get('name')?.dirty || appointmentForm.get('name')?.touched)) {
            <small class="text-red-500">İsim alanı zorunludur</small>
          }
        </div>

        <!-- Soyisim -->
        <div class="col-span-12 lg:col-span-6">
          <label for="surName" class="m-form-label">Soyisim</label>
          <input
            pInputText
            id="surName"
            formControlName="surName"
            placeholder="Soyisim"
            class="w-full">
          @if (appointmentForm.get('surName')?.invalid && (appointmentForm.get('surName')?.dirty || appointmentForm.get('surName')?.touched)) {
            <small class="text-red-500">Soyisim alanı zorunludur</small>
          }
        </div>

        <!-- Randevu Tarihi -->
        <div class="col-span-12 lg:col-span-6">
          <label for="appointmentDate" class="m-form-label">Randevu Tarihi</label>
          <p-datePicker
            id="appointmentDate"
            appendTo="body"
            formControlName="appointmentDate"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [readonlyInput]="true"
            dateFormat="dd.mm.yy"
            [showIcon]="true"
            styleClass="w-full"
            [showButtonBar]="true"
            placeholder="Randevu Tarihi">
            [touchUI]="true">
          </p-datePicker>
          @if (appointmentForm.get('appointmentDate')?.invalid && (appointmentForm.get('appointmentDate')?.dirty || appointmentForm.get('appointmentDate')?.touched)) {
            <small class="text-red-500">Randevu tarihi seçiniz</small>
          }
        </div>

        <!-- Randevu Saati -->
        <div class="col-span-12 lg:col-span-6">
          <label for="appointmentHour" class="m-form-label">Randevu Saati</label>
          <p-select
            appendTo="body"
            id="appointmentHour"
            formControlName="appointmentHour"
            placeholder="Randevu Saati"
            [options]="timeSlots"
            [showClear]="true"
            class="w-full">
          </p-select>
          @if (appointmentForm.get('appointmentHour')?.invalid && (appointmentForm.get('appointmentHour')?.dirty || appointmentForm.get('appointmentHour')?.touched)) {
            <small class="text-red-500">Randevu saati seçiniz</small>
          }
        </div>
        <div class="col-span-12">
          <label for="note" class="m-form-label">Not</label>
          <textarea id="note" rows="5" cols="30" class="w-full" pTextarea formControlName="note"></textarea>
          @if (appointmentForm.get('appointmentHour')?.invalid && (appointmentForm.get('appointmentHour')?.dirty || appointmentForm.get('appointmentHour')?.touched)) {
            <small class="text-red-500">Randevu saati seçiniz</small>
          }
        </div>
      </div>
    </div>
  </form>


    <div class="flex justify-end w-full gap-2 pt-2">
      <p-button
        label="Vazgeç"
        icon="pi pi-times"
        severity="secondary"
        (click)="ref.close()"
        [outlined]="true"
        [rounded]="true"
        pRipple>
      </p-button>
      <p-button
        label="Randevu Oluştur"
        icon="pi pi-check"
        (click)="onSubmit()"
        [disabled]="!appointmentForm.valid"
        [rounded]="true"
        pRipple>
      </p-button>
    </div>


