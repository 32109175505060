import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/magenty-ui/src/environments/environment';
import { catchError, of } from 'rxjs';
import { GlobalMessageService } from '../global-services/global-message-service/global-message.service';

@Injectable({
  providedIn: 'root',
})
export class SendService {
  globalMessageService = inject(GlobalMessageService);
  constructor(private http: HttpClient) {}

  supportMail(Title: string, Description: string) {
    return this.http
      .post<{ Result: { Status: boolean; Message: string; Data: any } }>(
        `${environment.baseUrl}/send/supportMail`,
        { Title, Description }
      )
      .pipe(
        catchError(() =>
          of({
            Result: {
              Status: false,
              Message: 'Bir hata Oluştu',
              Data: undefined,
            },
          })
        )
      );
  }
}
